/* You can add global styles to this file, and also import other style files */

@import "@angular/material/theming";

@include mat-core();

// Imports custom theme
@import "./theme.scss";

// Imports custom component themes
@import "./custom-component-themes.scss";

@include angular-material-theme($my-theme);
@include custom-components-theme($my-theme);

.dark-theme {
  @include angular-material-color($dark-theme);
  @include custom-components-theme($dark-theme);
}

// Overrides typography for all Angular Material
// @include angular-material-typography($typography);

html, body { 
  height: 100%; 
}

body {
  margin: 0; 
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
