@import "@angular/material/theming";
@mixin sidenav-component-theme($config-or-theme) {
//   // Retrieves variables from theme
  $config: mat-get-color-config($config-or-theme);
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);
  $warn: map-get($config, warn);
  $foreground: map-get($config, foreground);
  $background: map-get($config, background);

  .mat-drawer {
    background-color: mat-color($background, app-bar);
    color: mat-color($foreground, text);

    $color-list: (
      "primary": $primary,
      "accent": $accent,
      "warn": $warn
    );

    // @each $key, $val in $color-list {
    //   &[color="#{$key}"] {
    //     @include _mat-toolbar-color($val);
    //     .mat-list-base {
    //       .mat-list-item {
    //         color: mat-color($val, default-contrast);
    //       }
    //     }
    //   }
    // }
  }
}
